const env = process.env.NODE_ENV;
let bbaseURL: string = '/api';
let ggrpcURL: string = '/grpc';
export const loginURL: string = '/login';
if (env === 'development') {
    bbaseURL = 'http://localhost:8090/api';
    ggrpcURL = 'http://localhost:6566';
}
export const baseURL = bbaseURL;
export const grpcURL = ggrpcURL;

export const APP_NAME: {[id: string]: string} = {
    "Interlock": 'MB for Logic Diagrams',
    "PID": 'MB for P&IDs',
    "": ''
};
export const PROJECT_NAME_MAX_LENGTH = 33;
export const PROJECT_DESCRIPTION_MAX_LENGTH = 1000;
