import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFPageProxy, PDFWorker } from 'pdfjs-dist';
import { Middleware, MiddlewareAPI } from 'redux';
import { getType } from 'typesafe-actions';
import actions from '../actions/paper2pidservice';
import pdfActions from '../actions/pdfpages';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js';

const worker = new PDFWorker();

const PAGE = 1;

export function newPdfPageMiddleware(): Middleware {
    return ({ dispatch, getState }: MiddlewareAPI<ThunkDispatch, StoreState>) => (next: ThunkDispatch) => (action) => {
        if (action.type === getType(actions.fetchPageDataSuccess)) {
            handlePdfPage(getState, dispatch, action.meta, action.payload);
        }
        return next(action);
    };
}

function handlePdfPage(getState: () => StoreState, dispatch: ThunkDispatch, pageId: string, arraybuffer: ArrayBuffer) {
    dispatch(pdfActions.processPdfPageBegin(pageId));
    getDocument({
        data: arraybuffer,
        worker,
    }).promise.then((pdfDocument?: PDFDocumentProxy) => {
        if (pdfDocument) {
            pdfDocument.getPage(PAGE).then((pdfPage: PDFPageProxy) => {
                // dispatch results
                dispatch(pdfActions.processPdfPageSuccess(pdfPage, pageId));
            }).catch((error) => {
                // dispatch error
                dispatch(pdfActions.processPdfPageError(error, pageId));
            });
        }
    }).catch((error) => {
        // dispatch error
        dispatch(pdfActions.processPdfPageError(error, pageId));
    });
}
